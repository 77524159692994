export const name = 'externalComponent' as const

export const ExternalComponentAPISymbol = Symbol('ExternalComponentAPI')
export const ExternalComponentLoaderSymbol = Symbol('ExternalComponentLoader')
export const PartytownLoaderSymbol = Symbol('PartytownLoader')

export const TrustedExternalComponentLoaderSymbol = Symbol('TrustedExternalComponentLoader')
export const UntrustedExternalComponentLoaderSymbol = Symbol('UntrustedExternalComponentLoader')

export const ExternalComponentDsApiSymbol = Symbol('externalComponentDsApi')
export const ExternalComponentDsPublicApiSymbol = Symbol('externalComponentDsPublicApi')
